<template>
    <div class="list-page">
        <div class="wrap">
            <div class="z-flex z-flex-between" style="margin-top:30px">
                <div class="page-t">学院课程</div>
                <el-select v-model="selSort" placeholder="请选择" @change="sort">
                    <el-option
                            v-for="item in selOpts"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                    >
                    </el-option>
                </el-select>
            </div>
            <div class="sort-con">
                <div class="col z-flex z-flex-between" v-for="(item,index) in sortLabels" :key="index"
                     v-show="!item.checked">
                    <div class="col-l">{{item.title}}：</div>
                    <div class="col-r z-flex">
                        <span
                                :class="['sort-item z-flex z-flex-center',subItem.checked?'checked':'']"
                                v-for="(subItem,subIdx) in item.list"
                                :key="subIdx"
                                @click="checkLabels(item,subItem)"
                        >
                        {{subItem.label}}
                        </span>
                        <el-button
                                class="btn-multi"
                                plain
                                size="mini"
                                @click="selMulti(item)"
                                v-if="!item.ifMulti"
                        >
                            多选<i class="el-icon-plus"></i>
                        </el-button>
                        <div class="btn-multi-group" v-else>
                            <el-button size="small" @click="cancelMulti(item)">取消</el-button>
                            <el-button size="small" type="primary" @click="confirmMulti(item)">确定</el-button>
                        </div>
                    </div>
                </div>
                <div class="sel-bar z-flex">
                    <span>您已选择：</span>
                    <div class="sel-bar-r z-flex">
                        <el-tag class="sel-item" effect="dark" closable v-for="(item,index) in selArr" :key="index"
                                @close="closeTag(item.title,index)">
                            <span>{{item.title}}</span>
                            <el-divider direction="vertical"></el-divider>
                            <!-- <span>{{item.labels}}</span> -->
                            <span v-for="(subItem,subIdx) in item.selLabels" :key="subIdx">{{subItem.label}}，</span>
                        </el-tag>
                        <div class="btn-multi-group" v-if="selArr.length > 0">
                            <el-button size="small" @click="cancelSort">取消</el-button>
                            <el-button size="small" type="primary" @click="confirmSort">确定</el-button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="course-list z-flex">
                <courseItem
                        v-for="(item,index) in rowList"
                        :key="index"
                        :itemData=item
                >
                </courseItem>
            </div>
            <div class="page-wrap">
                <el-pagination
                        layout="prev, pager, next"
                        :total="rowTotal"
                        :current-page="currentPage"
                        @current-change="handleCurrentChange"
                        :page-size="pageSize"
                        :hide-on-single-page="true"
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import courseItem from "../components/courseItem"

    export default {
        components: {
            courseItem,
        },
        data() {
            return {
                currentPage: 1, //当前页数
                pageSize: 20, //每页20条
                rowTotal: 0,  //总数
                rowList: [], //列表
                selSort: '',
                selOpts: [
                    {
                        value: '',
                        label: '所有课程',
                    },
                    {
                        value: 'recom',
                        label: '推荐课程',
                    },
                    {
                        value: 'sub',
                        label: '订阅最多',
                    },
                ],
                sortLabels: [//标签
                    {
                        title: '标签',
                        list: []
                    },{
                        title: '老师',
                        list: []
                    },{
                        title: '等级',
                        list: []
                    }
                ],

                selArr: [ //选中标签
                ]
            };
        },
        watch: {
            // selArr(nval) {
            //     this.selArr = nval;
            //     if (this.selArr.length>0) {
            //         this.getRows();
            //     }
            // }
        },
        mounted() {
            if (this.$route.query.page) {
                this.currentPage = this.$route.query.page;
            }
            if (this.$route.query.scene) {
                this.selSort = this.$route.query.scene;
            }
            //获取初始化数据
            this.$axios.api.courseListInit({}, (res) => {
                if (res.code == 0) {
                    //初始化标签数据
                    this.sortLabels[0].list = res.data.categories;
                    this.sortLabels[1].list = res.data.teachers;
                    this.sortLabels[2].list = res.data.levels;
                    if (this.$route.query.category_id) {
                        var selArrars = this.$route.query.category_id.split(",");
                        for(var i=0; i < this.sortLabels[0].list.length; i++) {
                            if (selArrars.indexOf(this.sortLabels[0].list[i].id+"") >= 0) {
                                this.sortLabels[0].list[i].checked = true;
                            }
                        }
                        this.confirmMulti(this.sortLabels[0]);
                    }
                    if (this.$route.query.teacher_id) {
                        var selArrars = this.$route.query.teacher_id.split(",");
                        for(var i=0; i < this.sortLabels[1].list.length; i++) {
                            if (selArrars.indexOf(this.sortLabels[1].list[i].id+"") >= 0) {
                                this.sortLabels[1].list[i].checked = true;
                            }
                        }
                        this.confirmMulti(this.sortLabels[1]);
                    }
                    if (this.$route.query.level_id) {
                        var selArrars = this.$route.query.level_id.split(",");
                        for(var i=0; i < this.sortLabels[2].list.length; i++) {
                            if (selArrars.indexOf(this.sortLabels[2].list[i].id+"") >= 0) {
                                this.sortLabels[2].list[i].checked = true;
                            }
                        }
                        this.confirmMulti(this.sortLabels[2]);
                    }
                    //获取列表数据
                    this.getRows();
                } else {
                    console.log(res.msg);
                }
            });
        },
        methods: {
            //下拉筛选
            sort(e) {
                this.getRows();
            },
            //选择标签
            checkLabels(item, subItem) {
                if (!subItem.checked) {
                    this.$set(subItem, 'checked', true)
                } else {
                    this.$set(subItem, 'checked', false)
                }
                //单选
                if (!item.ifMulti) {
                    this.$set(item, 'checked', true)
                    const selItem = {
                        title: '',
                        selLabels: []
                    }
                    selItem.title = item.title
                    selItem.selLabels.push(subItem)
                    this.selArr.push(selItem)
                    this.confirmSort()
                }
            },
            // 多选
            confirmMulti(item) {
                const newArr = item.list.filter(subItem => subItem.checked === true)
                if (newArr.length === 0) {
                    return false
                }
                const selItem = {
                    title: '',
                    selLabels: []
                }
                selItem.title = item.title
                selItem.selLabels = newArr
                this.selArr.push(selItem)
                this.$set(item, 'checked', true)
            },
            // 多选开
            selMulti(item) {
                this.$set(item, 'ifMulti', true)
            },
            //多选关
            cancelMulti(item) {
                this.$set(item, 'ifMulti', false)
                item.list.map(subItem => {
                    this.$set(subItem, 'checked', false)
                })
            },
            // 关闭标签
            closeTag(title, index) {
                this.selArr.splice(index, 1)
                this.sortLabels.map(item => {
                    if (item.title === title) {
                        this.$set(item, 'checked', false)
                        this.$set(item, 'ifMulti', false)
                        item.list.map(subItem => {
                            this.$set(subItem, 'checked', false)
                        })
                    }
                })
                this.getRows();
            },
            //分页
            handleCurrentChange(currentPage) {
                this.currentPage = currentPage;
                this.getRows();
            },
            //获取数据
            getRows() {
                let selParams = {category_id: [], teacher_id: [], level_id: []};
                if (this.selArr.length) {
                    for (var i=0;i<this.selArr.length;i++) {
                        for (var j=0;j<this.selArr[i].selLabels.length;j++) {
                            let id = this.selArr[i].selLabels[j].id;
                            if (this.selArr[i].title == '标签') {
                                selParams.category_id.push(id);
                            } else if (this.selArr[i].title == '老师') {
                                selParams.teacher_id.push(id);
                            } else if (this.selArr[i].title == '等级') {
                                selParams.level_id.push(id);
                            }
                        }
                    }
                }
                let params = {
                    page: this.currentPage,
                    page_size: this.pageSize,
                    scene: this.selSort,
                    category_id: selParams.category_id.toString(),
                    teacher_id: selParams.teacher_id.toString(),
                    level_id: selParams.level_id.toString(),
                };
                this.$router.push({
                    path: '/list',
                    query: {
                        page: params.page,
                        scene: params.scene,
                        category_id: params.category_id,
                        teacher_id: params.teacher_id,
                        level_id: params.level_id,
                    }
                })
                this.$axios.api.courseList(params, (res) => {
                    if (res.code == 0) {
                        this.rowTotal = res.data.courses.total;
                        this.rowList = res.data.courses.data;
                    } else {
                        console.log(res.msg);
                    }
                });
            },
            cancelSort(){
                this.selArr = [];
                this.sortLabels.map(item => {
                    this.$set(item, 'checked', false)
                    this.$set(item, 'ifMulti', false)
                    item.list.map(subItem => {
                        this.$set(subItem, 'checked', false)
                    })
                })
            },
            confirmSort(){
                this.getRows();
            }
        },
    };
</script>

<style lang="scss" scoped>
    .page-t {
        font-size: 25px;
    }

    .sort-con {
        background-color: #fff;
        padding: 20px;
        margin-top: 30px;

        .col {
            align-items: flex-start;
            padding: 10px 0;
            margin-bottom: 10px;
            border-bottom: $borderDashed;

            .col-l {
                color: #666;
                line-height: 38px;
            }

            .col-r {
                width: calc(100% - 100px);
                position: relative;

                .btn-multi-group {
                    width: 100%;
                    text-align: center;
                }

                .btn-multi {
                    position: absolute;
                    right: 0;
                    top: 6px;
                }

                .sort-item {
                    cursor: pointer;
                    width: 100px;
                    height: 34px;
                    border: $border;
                    border-color: #fff;
                    margin: 0 8px 8px 0;
                    border-radius: 4px;

                    &:hover {
                        color: $colorMain;
                    }

                    &.checked {
                        border-color: #d9ecff;
                        color: $colorMain;
                        background-color: #ecf5ff;
                    }
                }
            }
        }

        .sel-bar {
            margin-top: 15px;
            align-items: flex-start;

            .sel-bar-r {
                margin-top: -5px;
                width: calc(100% - 100px);
                .btn-multi-group {
                    width: 100%;
                    text-align: center;
                }
            }

            .sel-item {
                margin: 0 8px 8px 0;
            }
            
        }
    }

    .course-list {
        margin: 30px 0 20px;

        .course-item {
            margin: 0 20px 20px 0;

            &:nth-child(4n) {
                margin: 0 0 20px 0;
            }
        }
    }
</style>
